import { set as setProductImage } from './product-images';

const HIDE_MASTER_IMAGE = false;

document.addEventListener('DOMContentLoaded', init);
window.addEventListener('popstate', popVariantPathFromHistory);

export default function init() {

  const variants = document.querySelectorAll('[data-variant-id]');
  const resetButton = document.querySelector('[data-variant-reset-button]');
  const variantOptions = [...document.querySelectorAll('[data-variant-option]')];
  const selectedOptions = returnSelectedVariantOptions(variantOptions);

  if (variants.length === 0) return;

  // If selected options exist upon initialisation we
  // select them so the appropriate content is shown
  if (selectedOptions.length > 0) {
    selectedOptions.map(option => {
      select(option, variantOptions, selectedOptions);
    });
  }

  // Don't show duplicate images on master variant
  else {
    hideDuplicateImageThumbails();
    restoreSessionSelections(variantOptions);
  }

  // Event listeners
  variantOptions.map(option => {
    option.addEventListener('change', event => {
      select(event.target, variantOptions);
      storeSessionSelections(variantOptions);
      pushVariantPathToHistory(variantOptions);
    });
  });

  document.querySelectorAll('[data-variant-group] select').forEach(selectElem => {
    selectElem.addEventListener('change', event => {
      const option = [...event.target.querySelectorAll('option')].filter(o => o.selected)[0];

      select(option, variantOptions);
      storeSessionSelections(variantOptions);
      pushVariantPathToHistory(variantOptions);
    })
  })

  if (resetButton) {
    resetButton.addEventListener('click', () => {
      reset(variantOptions);
      pushMasterPathToHistory();
    });
  }

  //Temporarily show all hidden content from old selector
  [...document.querySelectorAll('[data-variant-selector]')].map(node => {
    node.style.display = 'block';
  });
}

export function select(option, options, selected = null) {
  const bundle = document.querySelector('[data-bundle]');

  disableOrEnableVariantOptions(option, options);

  // TODO: Should be cleaned up when integrated with core
  if (bundle) {
    const variants = document.querySelectorAll('[data-variant-id]');
    const leadProduct = document.querySelector('[data-lead-product]');
    const options = [...leadProduct.querySelectorAll('[data-variant-option]')];
    const selectedOptions = returnSelectedVariantOptions(options);
    const requiredSelections = document.querySelectorAll('[data-variant-group]').length;
    const selectedVariant = returnSelectedVariantAsString(selectedOptions, requiredSelections);
    const leadProductImage = document.querySelector('[data-lead-product-image]');

    [...variants].map(variant => {
      if (variant.dataset.variantId === selectedVariant) {
        variant.classList.remove('is-hidden');
        if (variant.getAttribute('data-variant-image-src') && leadProductImage) {
          leadProductImage.src = variant.getAttribute('data-variant-image-src');
        }
        return;
      }
      variant.classList.add('is-hidden');
    });

  } else {
    const selectedOptions = selected || returnSelectedVariantOptions(options);
    const requiredSelections = document.querySelectorAll('[data-variant-group]').length;
    const selectedVariant = returnSelectedVariantAsString(selectedOptions, requiredSelections);
    const canAddToCart = selectedOptions.length === requiredSelections;

    if (canAddToCart) {
      _moveMasterThumbnail();
      setProductImages(selectedVariant);
      if(moveVariantSelector(selectedVariant)) {
        showOrHideVariantContent(selectedVariant);
        enableAddToCart();
      } else {
        // We are in an invalid state, best to disable the add to cart button
        disableAddToCart();
      }
    }

  }
}

export function enableAddToCart() {
  [...document.querySelectorAll('[data-add-to-cart], [data-buy-now]')].map(selectInput => {
    selectInput.disabled = '';
  })
}

export function disableAddToCart() {
  [...document.querySelectorAll('[data-add-to-cart], [data-buy-now]')].map(selectInput => {
    selectInput.disabled = 'disabled';
  })
}

export function reset(variantOptions) {
  // Unselect all the options and checkboxes
  variantOptions.map(optionInput => {
    if (optionInput.tagName === 'OPTION') optionInput.selected = false;
    if (optionInput.tagName === 'INPUT') optionInput.checked = false;
    optionInput.disabled = false;
  });
  // Reset all select boxes to the null state
  [...document.querySelectorAll('[data-variant-group] select')].map(selectInput => {
    [...selectInput.querySelectorAll('option[disabled]')].map(optionInput => {
      optionInput.selected = true;
    });
  });
  _moveMasterThumbnail('prepend');
  disableAddToCart();
  setProductImages();
  moveVariantSelector();
  showOrHideVariantContent();
  hideDuplicateImageThumbails();
  clearSessionSelections();
}

function setProductImages(selectedVariant) {
  const source = findAndReturnActiveVariant('[data-product-images]');
  const target = findAndReturnSelectedVariant(selectedVariant, '[data-product-images]');

  // Return if source or target are null or undefined
  if (source === null || target === null) {
    return;
  }

  const active = JSON.parse(target.dataset.productImages);
  const thumbs = document.querySelectorAll('[data-product-thumbnail]');
  const activeThumbs = returnActiveFromThumbnails(active, thumbs);

  // Move products images to the active variant container
  [...source.children].map(node => target.appendChild(node));

  // rearrange the images for the selected variant to reflect its order
  const list = target.querySelector('[data-product-thumbnails]');
  let index = [];
  try { index = JSON.parse(target.getAttribute('data-product-images')); } catch {}

  if (list && index.length > 0) {
    [...list.children]
      .forEach(node => {
        const idx = index.indexOf(node.id);
        idx >= 0 ? list.insertBefore(node, list.children[idx]) : list.appendChild(node);
      });
  }

  if (thumbs.length === 0) return;

  if (activeThumbs.length > 0) {
    // Show only thumbnails associated with the selected variant
    [...thumbs].map(thumb => thumb.classList.add('is-hidden'));
    [...activeThumbs].map(thumb => thumb.classList.remove('is-hidden'));
    // Set main product image
    setProductImage(activeThumbs[0].id);
    // Hide master image if variant is selected
    if (selectedVariant && HIDE_MASTER_IMAGE) {
      _masterThumbnails().map(thumb => thumb.classList.add('is-hidden'));
    }

    // If no images belong to this variant use the master image
  } else {
    setProductImage(thumbs[0].id);
    [...thumbs].map(thumb => thumb.classList.add('is-hidden'));
  }
}

function returnActiveFromThumbnails(activeThumbnails, thumbnails) {
  return [...thumbnails].filter(thumb => {
    const isActive = activeThumbnails.includes(thumb.id);
    const isNotMasterImage = thumb !== thumbnails[0];

    if (HIDE_MASTER_IMAGE) {
      return isActive && isNotMasterImage;
    }
    return isActive;
  });
}

function hideDuplicateImageThumbails() {
  const thumbnails = document.querySelectorAll('[data-product-thumbnail]');

  if (thumbnails.length === 0) return;

  let uniqueUrls = [];

  [...thumbnails]
    .filter(thumb => !_isMasterThumbnail(thumb))
    .map(thumb => {
      if (uniqueUrls.includes(thumb.dataset.srcZoom)) {
        thumb.classList.add('is-hidden');
        return;
      }
      uniqueUrls.push(thumb.dataset.srcZoom);
      thumb.classList.remove('is-hidden');
    });

  if (HIDE_MASTER_IMAGE) {
    _masterThumbnails().map(thumb => thumb.classList.add('is-hidden'));
  }
}

function moveVariantSelector(selectedVariant) {
  const source = findAndReturnActiveVariant('[data-variant-option-selector]');
  const target = findAndReturnSelectedVariant(selectedVariant, '[data-variant-option-selector]');

  if (source && target) {
    [...source.children].map(node => target.appendChild(node));
    return  selectedVariant;
  } else {
    return null;
  }
}

function showOrHideVariantContent(selectedVariant) {
  [...document.querySelectorAll('[data-variant-id]')].map(node => {
    const isMaster = !node.dataset.variantId;
    const isSelected = isMaster ?
      selectedVariant === undefined :
      node.dataset.variantId === selectedVariant;

    node.style.display = isSelected ? 'block' : 'none';
  });
}

function disableOrEnableVariantOptions(option, variantOptions) {
  const allowedOptions = option.dataset.variantAllowedOptions;
  const optionType = option.dataset.type;

  if (!allowedOptions || allowedOptions === "{}") return;

  [...document.querySelectorAll('[data-variant-option]')].map(group => {
    const groupType = group.dataset.type;
    const optionsInGroup = variantOptions.filter(o => o.dataset.type === groupType);

    if (groupType === optionType) return;
    optionsInGroup.map(option => {
      option.disabled = !JSON.parse(allowedOptions)[groupType].includes(option.value);
    });
  });
}

function pushMasterPathToHistory() {
  const selectorNode = document.querySelector('[data-variant-option-selector]');
  const masterName = selectorNode.dataset.masterName;
  const masterPath = selectorNode.dataset.masterPath;
  window.history.pushState(masterName, null, masterPath);
}

function pushVariantPathToHistory(variantOptions) {
  // Skip if we are on a bundle product
  if (document.querySelector('[data-bundle-product]')) { return }

  const selectedIds = returnSelectedVariantOptions(variantOptions).map(elem => elem.id);
  const selectorNode = document.querySelector('[data-variant-option-selector]');
  const variantProductInfo = JSON.parse(selectorNode.dataset.variantProductInfo);

  // Using JSON.stringify is a hack, but the arrays are always parameterized strings, so it should be fine
  const variantKey = Object.keys(variantProductInfo).find(key => JSON.stringify(variantProductInfo[key].selector) === JSON.stringify(selectedIds));
  const variantProduct = variantProductInfo[variantKey];

  if (variantProduct === undefined || window.location.pathname === variantProduct.path) { return }

  window.history.pushState(variantProduct.name, null, variantProduct.path);
}

function popVariantPathFromHistory(event) {
  // Skip if we are on a bundle product
  if (document.querySelector('[data-bundle-product]')) { return }

  const selectorNode = document.querySelector('[data-variant-option-selector]');
  const productPath = document.location.pathname;
  const masterPath = selectorNode.dataset.masterPath;
  const variantOptions = [...document.querySelectorAll('[data-variant-option]')];

  if (productPath === masterPath) {
    reset(variantOptions);
    return;
  }

  const variantProductInfo = JSON.parse(selectorNode.dataset.variantProductInfo);
  const variantKey = Object.keys(variantProductInfo).find(key => variantProductInfo[key].path === productPath);
  const variantProduct = variantProductInfo[variantKey];

  if (variantProduct === undefined) { return }

  const selectedVariantIds = variantProduct.selector;

  selectSelectedIds(variantOptions, selectedVariantIds);
}

function returnSelectedVariantOptions(variantOptions) {
  return variantOptions
    .filter(opt => opt.checked || opt.selected)
    .sort((a, b) => {
      if (a.dataset.type < b.dataset.type) return -1;
      if (a.dataset.type > b.dataset.type) return 1;
      return 0;
    });
}

function storeSessionSelections(variantOptions) {
  const selectorNode = document.querySelector('[data-variant-option-selector]');
  const masterId = selectorNode.dataset.masterId;
  const selectedIds = returnSelectedVariantOptions(variantOptions).map(elem => elem.id);
  window.sessionStorage.setItem(`${masterId}:selected-variants`, JSON.stringify(selectedIds));
}

function clearSessionSelections() {
  const selectorNode = document.querySelector('[data-variant-option-selector]');
  const masterId = selectorNode.dataset.masterId;
  window.sessionStorage.removeItem(`${masterId}:selected-variants`);
}

function restoreSessionSelections(variantOptions) {
  const selectorNode = document.querySelector('[data-variant-option-selector]');
  if (!selectorNode) { return; }
  const masterId = selectorNode.dataset.masterId;
  const selectedVariantIds = JSON.parse(window.sessionStorage.getItem(`${masterId}:selected-variants`));
  selectSelectedIds(variantOptions, selectedVariantIds);
  pushVariantPathToHistory(variantOptions);
}

// Selected IDs is something like ['color-multi', 'size-37']
function selectSelectedIds(variantOptions, selectedVariantIds) {
  let selectedIds = null;

  // Parse the array and clean it of nulls and empty strings if they exist
  if (Array.isArray(selectedVariantIds)) {
    selectedIds = selectedVariantIds.filter(x => x);
  }

  if (selectedIds) {
    selectedIds.forEach(id => {
      var option = document.querySelector(`#${id}`);

      if (!option) return

      if (option.tagName === 'OPTION') {
        option.selected = true;
        select(option, variantOptions);
      } else {
        option.checked = true;
        select(option, variantOptions);
      }
    });
  }
}

function findAndReturnActiveVariant(selector) {
  const variants = [...document.querySelectorAll('[data-variant-id]')]
    .filter(node => node.style.display === 'block')[0];

  if (variants) { return variants.querySelector(selector); }

  return undefined;
}

function findAndReturnSelectedVariant(selectedVariant, selector) {
  return selectedVariant ?
    document.querySelector(`[data-variant-id="${selectedVariant}"] ${selector}`) :
    document.querySelector(`[data-variant-id] ${selector}`);
}

function returnSelectedVariantAsString(selectedOptions, requiredSelections) {
  if (selectedOptions.length === requiredSelections) {
    return selectedOptions.map(option => option.value).join(' ');
  }
}

// private functions

function _isMasterThumbnail(thumb) {
  return thumb.getAttribute('data-product-master') === 'true';
}

function _masterThumbnails() {
  return [...document.querySelectorAll('[data-product-master=true]')];
}

function _moveMasterThumbnail(action = 'append') {
  if (HIDE_MASTER_IMAGE) { return; }

  const thumbnailContainer = document.getElementsByClassName('SC-ProductImages_thumbnails_track')[0];
  const masterThumbnails = _masterThumbnails();
  masterThumbnails.map(thumb => {
    thumb.remove();
    if (action === 'append')
      thumbnailContainer.append(thumb);
    else
      thumbnailContainer.prepend(thumb);
  })
}
